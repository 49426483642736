<div *ngIf="property && entityData && (template === 'template1' || template === 'template4')" id="print-page">
  <div class="page">
    <div id="Table_01" class="boxWaper">
      <div class="content-pdf">
        <div *ngIf="entityData?.logo" id="logo">
          <span class="wrap">
            <img [src]="entityData.logo" style="max-height: 120px;" />
          </span>
        </div>
        <div id="contactos">
          <span *ngIf="entityData?.name" class="nome">
            {{entityData.name}}
            <br />
          </span>
          <span class="textcont">

            <span *ngIf="entityData?.address" class="morada">{{entityData.address}}
            </span>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center" *ngIf="entityData?.phone">
                <ion-icon class="me-1" src="assets/new-icons/call-outline.svg"></ion-icon>
                <span class="telemovel mb-0">{{entityData.phone}}</span>
              </div>
              <div class="d-flex align-items-center" *ngIf="entityData?.email">
                <ion-icon class="me-1" src="assets/new-icons/mail-outline.svg"></ion-icon>
                <span class="email">
                  <a href="mailto:{{entityData.email}}" style="text-decoration: none;">
                    <span color="#1A1A1A">{{entityData.email}}</span>
                  </a>
                </span>
              </div>
              <div class="d-flex align-items-center" *ngIf="entityData?.licenseImo">
                <ion-icon class="me-1" src="assets/new-icons/information-circle-outline.svg"></ion-icon>
                <span class="AMI">AMI: {{entityData.licenseImo}}</span>
              </div>
            </div>
          </span>
        </div>
        <div class="septop"></div>
      </div>
      <div class="content-pdf fotos">
        <ng-container *ngFor="let img of property?.media; let i = index">
          <img *ngIf="i < 3" [src]="img.file" class="thumb" />
        </ng-container>
      </div>

      <div class="content-pdf">
        <div class="title-box">
          <span *ngIf="property?.title" class="titleImo">
            {{property.title}}
          </span>
          <span *ngIf="property?.district" class="loca">{{property.district}}</span>
        </div>
        <div class="box30">

          <ul class="businessList">
            <li id="bus1">
              <span *ngIf="property.businessType" class="label soum">{{property.businessType}}</span>
              <span class="value">{{property.price != 0 && property.price != null ?
                priceFormat(property.price, property.currency) :
                't-on-request' | translate}}</span>
            </li>
          </ul>

          <span *ngIf="property?.reference" class="business ref"><b>Ref:</b> {{property.reference}}</span>
        </div>
      </div>

      <div class="content-pdf">
        <div class="tituloDes detalhes">{{'t-details' | translate}}</div>
        <ul class="textodet">


          <li *ngIf="property?.propertyCondition"><span>{{'FILTERS.PROPERTY-STATE.TEXT' | translate}}:
            </span>{{property.propertyCondition}} </li>
          <li *ngIf="property?.propertyType"><span>{{'t-propertyType' | translate}}:
            </span>{{property.propertyType}} </li>
          <li *ngIf="property?.businessType"><span>{{'t-businessType' | translate}}:
            </span>{{property.businessType}} </li>

        </ul>
      </div>
      <div *ngIf="property.description != ''" class="content-pdf">
        <div class="tituloDes">
          {{'t-description' | translate}}
        </div>
        <div class="textoDes"><span [innerHTML]="property.description"></span></div>
      </div>
      <div *ngIf="property?.divisions && property?.divisions?.length > 0" class="content-pdf">
        <div class="tituloDes detalhes">{{'t-characteristics' | translate}}</div>
        <ul class="textodet">
          <ng-container *ngFor="let division of property?.divisions">
            <li *ngIf="division.tag == 'Bedrooms' && division.value != 0">
              <i class="fa fa-bed me-2" aria-hidden="true"></i>
              <span>{{'t-beds' | translate}}:
                {{division.value}}</span>
            </li>
            <li *ngIf="division.tag == 'Bathrooms' && division.value != 0">
              <i class="fa fa-bath me-2" aria-hidden="true"></i>
              <span>{{'t-baths' | translate}}: {{division.value}}</span>
            </li>
            <!-- <li *ngIf="division.tag == 'garages'">
                            <i class="fa fa-car me-2" aria-hidden="true"></i>
                            <span>{{'t-garages' | translate}}: {{division.value}}</span>
                        </li> -->
            <li *ngIf="division.tag == 'Kitchens' && division.value != 0">
              <i class="fa fa-columns me-2" aria-hidden="true"></i>
              <span>{{'t-kitchens' | translate}}: {{division.value}}</span>
            </li>

          </ng-container>

        </ul>
      </div>
      <div class="content-pdf position-relative">
        <div class="sepbottom"></div>
        <div class="qr-code-box d-grid">
          <span class="qrtext">
            {{'t-qr-code-text' | translate}}
            <br>
            <a [href]="qrCodeLink">{{qrCodeLink}}</a>
          </span>
          <span *ngIf="qrCodeLink">
            <qrcode [qrdata]="qrCodeLink"></qrcode>
          </span>
        </div>
        <div class="bottom-box">

          Powered by Imodigi

        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="property && entityData && template === 'template2'" id="template2" class="print-property mt-3">
  <div class="align-items-center d-flex header">
    <div class="entity-logo p-3">
      <img class="float-end me-3" [src]="entityData.logo" width="120" />
      <!-- <img class="float-end me-3" src="https://admin-api.imodigi.com/entities/files/26-27-1716540321278-9fca615b-9349-4e5d-b710-9ae9c61c3186.png" width="120" /> -->
    </div>
    <div class="basic-info ms-3">
      <h3 *ngIf="property?.title" class="fs-2 fw-bold main-color">
        {{property.title}}
      </h3>
      <div class="align-items-center d-flex justify-content-between w-100">
        <div>
          <div *ngIf="property?.district" class="fs-5 fw-normal main-color">{{property.district}}</div>
          <div *ngIf="property?.reference" class="fs-5 fw-bold main-color">Ref: {{property.reference}}</div>
        </div>
        <h3 *ngIf="property?.price" class="fs-2 fw-bold main-color">
          {{property.price != 0 && property.price != null ?
          priceFormat(property.price, property.currency) :
          't-on-request' | translate}}
        </h3>
      </div>
    </div>
  </div>
  <div class="mt-4 mx-4">
    <div *ngIf="property?.media && property?.media?.length > 0" class="gallery mb-4">
      <div class="row">
        <div [ngClass]="{'col-12': property.media.length === 1, 'col-6': property.media.length > 1}">
          <img [src]="property.media[0].file" [alt]="property.media[0]?.description"
              class="property-image first-image rounded-5 w-100 d-block">
        </div>
        <div *ngIf="property.media.length > 1 " [ngClass]="{'grid-col-1': property.media.length === 2}"
          class="min-images col-6">
          <ng-container *ngFor="let img of property.media.slice(1, 5); let i = index">
            <div *ngIf="i != 3 || (i == 3 && property.media.length === 5)">
              <img [src]="img.file" [alt]="img?.description"
                  [ngClass]="property.media.length === 2 ? 'h-30v' : 'h-min-img'"
                  class="property-image h-100 rounded-5 d-block">
            </div>
            <div class="position-relative d-flex align-items-center justify-content-center" *ngIf="i == 3 && property.media.length > 5">
              <img [src]="img.file" [alt]="img?.description" class="property-image img-with-qr h-100 h-min-img rounded-5 d-block">
              <qrcode class="qr-code-image position-absolute" [width]="120" [margin]="2" [qrdata]="qrCodeLink"></qrcode>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="content">
      <h4 class="main-color fw-bold">{{'t-details' | translate}}</h4>
      <div class="row mb-4">
        <div *ngIf="property.propertyType" class="col-4">
          <span class="fw-bold me-1">{{ "t-propertyType" | translate
            }}:</span>
          <span class="det">{{ property.propertyType }}</span>
        </div>
        <div *ngIf="property.businessType" class="col-4">
          <span class="fw-bold me-1">{{ "t-businessType" | translate
            }}:</span>
          <span class="det">{{ property.businessType }}</span>
        </div>
        <div *ngIf="property.propertyCondition" class="col-4">
          <span class="fw-bold me-1">{{ "FILTERS.PROPERTY-STATE.TEXT" | translate
            }}:</span>
          <span class="det">{{ property.propertyCondition }}</span>
        </div>
      </div>
      <h4 *ngIf="property.description" class="main-color fw-bold">{{'t-description' | translate}}</h4>
      <p *ngIf="property.description" class="description" [innerHTML]="property.description"></p>
    </div>
    <div class="contacts mt-3">
      <h4 class="main-color fw-bold">Entre em contacto</h4>
      <div class="d-flex align-items-center justify-content-between">
        <div *ngIf="property?.agents?.length > 0" class="d-flex align-items-center">
          <img class="me-3" *ngIf="property?.agents?.length > 0" src="{{property?.agents[0]?.profileImg}}" width="80">
          <div class="agent-info">
            <h4 class="main-color">{{property?.agents[0]?.name}}</h4>
            <h4 class="main-color">{{property?.agents[0]?.phone}}</h4>
          </div>
        </div>
        <div *ngIf="!property?.agents || property?.agents?.length == 0" class="d-flex align-items-center">
          <img class="me-3" src="{{entityData?.transparentPagesLogo ? entityData?.transparentPagesLogo : entityData.logo}}" width="80">
          <div class="agent-info">
            <h4 class="main-color">{{entityData.name}}</h4>
            <h4 class="main-color">{{entityData.phone}}</h4>
          </div>
        </div>
        <div class="address">
          <p class="main-color text-end">AGII Center<br>Rua Cónego Luciano Afonso dos Santos, 37-B<br>4700-048 Braga<br>AMI:
            24377</p>
        </div>
      </div>
      <small class="main-color">Cada agente / agência é uma entidade jurídica e financeira independente.</small>
    </div>
  </div>
</div>
