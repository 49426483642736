import { Component, Input, OnInit } from '@angular/core';
import { EntityData } from 'src/app/models/entity-app.model';
import { PropertyModel } from 'src/app/models/property.model';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-property-print',
  templateUrl: './property-print.component.html',
  styleUrls: ['./property-print.component.css']
})
export class PropertyPrintComponent implements OnInit {

  @Input() property!: PropertyModel;
  @Input() entityData!: EntityData;
  @Input() qrCodeLink: any;
  @Input() template: any;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.eventService.subscribe('open-print', data => {
      this.property = data.property;
      if (this.property.description) {
        this.property.description = this.property.description.replace(/<p>&nbsp;<\/p>/g, '');
      }
      this.qrCodeLink = data.link;
      setTimeout(() => {
        window.print();
      }, 600)
    });
  }

  priceFormat(propertyPrice: any, symbol: any = '€') {
    if (propertyPrice) {
      let price = propertyPrice.toString().split('.');
      const spaces = (price: any) => String(price)
        .replace(
          /(?!^)(?=(?:\d{3})+$)/g,
          ' '
        );
      const price1 = spaces(price[0]);
      if (price.length > 1)
        return symbol + ' ' + price1 + ',' + price[1];

      return symbol + ' ' + price1;
    }
    return propertyPrice;
  }

}
